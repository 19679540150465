<template>
  <div id="hero">
    <div class="wrapper">
      <h1>Lägg till en nyhet</h1>
    </div>
  </div>

  <section>
    <div class="form-1">
      <router-link to="news" class="link-4">Gå till nyheter</router-link>

      <div class="input-1">
        <label for="">Rubrik:</label>
        <input
          type="text"
          v-model="name"
          placeholder="Skriv en rubrik..."
          maxlength="50"
        />
      </div>
      <div class="input-1">
        <label for="">Brödtext:</label>
        <WritingToolsComp
          :content="content"
          @change-content="content = $event"
        />
        <textarea
          v-model="content"
          placeholder="Berätta om nyheten..."
          class="min-height200"
          id="content"
          maxlength="2000"
        ></textarea>
      </div>

      <fieldset class="written-result">
        <legend>Resultat:</legend>
        <div class="wrapper">
          <h3>{{ name }}</h3>
          <p
            v-for="paragraph in content.split('\n')"
            :key="paragraph"
            class="no-margin"
            v-html="paragraph"
          ></p>
          <p class="cursive">
            Senast redigerad: {{ new Date().getDate() }}/{{
              new Date().getMonth() + 1
            }}-{{ new Date().getFullYear() }}
          </p>
        </div>
      </fieldset>

      <button class="font-size-1 button-1 mt-1" @click="addNew()">
        Lägg till
      </button>
    </div>
  </section>
</template>

<script>
import { auth, db } from "../firebase";
import { collection, addDoc } from "firebase/firestore";

import WritingToolsComp from "@/components/WritingToolsComp.vue";

const newsCollectionRef = collection(db, "news");

export default {
  name: "NewsAddComp",
  components: {
    WritingToolsComp,
  },

  data() {
    return {
      name: "",
      content: "",
    };
  },

  created() {
    document.title = this.$route.meta.title;
  },

  methods: {
    async addNew() {
      if (!auth.currentUser) {
        alert("Krävs admin-inloggning för att göra denna åtgärden!");
        return;
      }

      const date = new Date();
      // Add a new document with a generated id.
      await addDoc(newsCollectionRef, {
        // await to make sure script waits for adding doc before run "getNews()"
        name: this.name,
        content: this.content,
        date: Date.now(),
        lastEditDate: `${date.getDate()}/${
          date.getMonth() + 1
        }-${date.getFullYear()}`,
      });

      alert(`Nyheten "${this.name}" har nu lagts till.`);

      // Clear inputfields
      // this.name = '';
      // this.content = '';

      this.$router.push("/news");
    },
  },
};
</script>
