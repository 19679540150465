<template>
  <div id="hero">
    <div class="wrapper">
      <h1>Nyheter</h1>
    </div>
  </div>

  <section
    v-if="$store.state.user"
    style="background-color: var(--black1); color: var(--white)"
  >
    <h2 class="">Adminverktyg</h2>
    <router-link to="/news-add" class="link-4">Lägg till nyhet</router-link>
  </section>
  <section v-for="_new in newsList" :key="_new.id" v-bind:id="_new.id">
    <div class="wrapper">
      <h3>{{ _new.name }}</h3>
      <p
        v-for="paragraph in _new.content.split('\n')"
        :key="paragraph"
        class="no-margin"
        v-html="paragraph"
      ></p>
      <!-- <a
          v-if="paragraph.includes('<a>') && paragraph.includes('</a>')"
          :href="paragraph.slice(3).slice(0, -4)"
          target="_blank"
          rel="noopener noreferrer"
          class="link-4"
          >{{ paragraph.slice(3).slice(0, -4) }}</a
        >
        <span v-else>{{ paragraph }}</span> -->
      <p class="cursive">Senast redigerad: {{ _new.lastEditDate }}</p>

      <div class="new_buttons" v-if="$store.state.user">
        <router-link
          :to="{ name: 'NewsEdit', params: { id: _new.id } }"
          class="link-1 danger"
          >✎ Redigera</router-link
        >
      </div>
    </div>
  </section>
  <section id="prev-next-section">
    <button v-on:click="showPrevNews" id="prev-btn" class="link-3" disabled>
      Föregående
    </button>
    <div class="line"></div>
    <button v-on:click="showNextNews" id="next-btn" class="link-3" disabled>
      Nästa
    </button>
  </section>
</template>

<script>
import { db } from "../firebase";
import { collection, getDocs, query, orderBy } from "firebase/firestore";

export default {
  name: "NewsComp",
  data() {
    return {
      maxNewsShown: 5,
      newsShownStartAt: 0,
      newsList: [],
      totalNewsAmount: 0,
    };
  },
  created() {
    document.title = this.$route.meta.title;
  },
  async beforeMount() {
    // if no page param is set, default it to first page: 0
    if (!this.$route.params.page) {
      this.$route.params.page = 0;
    } else if (!/^\d+$/.test(this.$route.params.page)) {
      // If this.$route.params.page contains other symbols than numbers. Only numbers allowed.
      this.$route.params.page = 0;
      console.warn(
        "Wrong format on page param. Only numbers allowed. Default to page: 0, aka the latest news."
      );
    }

    // no negative new indexes exist
    if (this.$route.params.page < 0) {
      console.warn("No news available with negative indexes.");
      this.$router.push({ name: "NewsPageSpecified", params: { page: 0 } }); // Go to index 0
    }

    // ...params.page gets page 1 as page 1, but index starts at 0
    this.newsShownStartAt = this.$route.params.page * this.maxNewsShown;

    await this.getNews();
  },
  methods: {
    showPrevNews() {
      let _newPage = -1;
      if (this.$route.params.page) {
        _newPage += parseInt(this.$route.params.page);
      }
      this.$router.push({
        name: "NewsPageSpecified",
        params: { page: _newPage },
      });
    },
    showNextNews() {
      let _newPage = +1;
      if (this.$route.params.page) {
        _newPage += parseInt(this.$route.params.page);
      }
      this.$router.push({
        name: "NewsPageSpecified",
        params: { page: _newPage },
      });
    },

    async getNews() {
      const querySnapshot = await getDocs(
        query(collection(db, "news"), orderBy("date", "desc"))
      );
      let _news = [];

      let _index = 0;
      querySnapshot.forEach((doc) => {
        // only get news starting at newsShownStartAt and going maxNewsShown
        if (
          _index >= this.newsShownStartAt &&
          _index < this.newsShownStartAt + this.maxNewsShown
        ) {
          const _new = {
            id: doc.id,
            name: doc.data().name,
            content: doc.data().content,
            lastEditDate: doc.data().lastEditDate,
          };

          _news.push(_new);
        }

        _index++;
      });
      this.newsList = _news;
      this.totalNewsAmount = querySnapshot.size;

      this.checkNewsAwailable();
    },
    checkNewsAwailable() {
      const _lastAvailablePage = Math.floor(
        (this.totalNewsAmount - 1) / this.maxNewsShown
      );

      if (this.newsShownStartAt >= this.totalNewsAmount) {
        console.warn(
          "No news to show. Not this many news available. You want to show new with index: " +
            this.newsShownStartAt +
            ". Total news published to view is only: " +
            this.totalNewsAmount +
            "."
        );

        // Go to news at very end (aka first news published)
        this.$router.push({
          name: "NewsPageSpecified",
          params: { page: _lastAvailablePage },
        });
      }

      // Check if can go to prev or next news
      if (_lastAvailablePage > parseInt(this.$route.params.page)) {
        // Can go to next news (aka older news)
        document.querySelector("#next-btn").disabled = false;
      }
      if (this.$route.params.page > 0) {
        // Can go to prev news (aka newer news)
        document.querySelector("#prev-btn").disabled = false;
      }
    },
  },
};
</script>

<style scoped>
section > .wrapper {
  gap: 0.5rem;
}

.focused-part {
  border-bottom: 1px solid var(--black1);
}
</style>
