<template>
  <div id="hero">
    <div class="wrapper">
      <h1>Google Kalender</h1>
      <p>
        Vi har en Google Kalender där alla enkelt kan ta del av saker som sker i
        och runt föreningen.
      </p>
    </div>
  </div>

  <section id="first-section">
    <div class="wrapper">
      <iframe
        src="https://calendar.google.com/calendar/embed?height=600&wkst=2&bgcolor=%23ffffff&ctz=Europe%2FStockholm&showTitle=0&showPrint=0&showTabs=0&showCalendars=0&showTz=0&src=bm9ycmFnb3RsYW5kc21oZnVuZ2RvbUBnbWFpbC5jb20&src=c3Yuc3dlZGlzaCNob2xpZGF5QGdyb3VwLnYuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&color=%237986CB&color=%23B39DDB"
        style="border-width: 0"
        width="100%"
        height="600"
        frameborder="0"
        scrolling="no"
      ></iframe>
    </div>
  </section>
</template>

<script>
export default {
  name: "CalendarComp",
  created() {
    document.title = this.$route.meta.title;
  },
};
</script>

<style scoped>
.wrapper {
  align-items: center;
}
</style>
