<template>
  <div id="hero">
    <div class="wrapper">
      <h1>Om oss</h1>
    </div>
  </div>

  <section id="first-section">
    <div class="wrapper">
      <h2>Vår verksamhet</h2>
      <p>
        Norra Gotlands MHF-Ungdom har som främsta fokus att alla barn skall få
        prova på att köra tvåhjuliga motorfordon. Vi har ett 30-tal motorfordon
        på våran anläggning, allt från crossar, mopeder, trailbikes och en
        elcross. Föreningen står för allt underhåll av dessa fordon, inkluderat
        bensin. Vi har även en del utrustning att låna ut, såsom crosshjälmar.
        Det ända som krävs för att få prova att köra cross hos oss är
        heltäckande oömma kläder. Efter du har provat 2 gånger kostar det 400 kr
        per år för medlemskap. För att detta ska gå runt från föreningens sida
        är vi beroende av bidrag, det är därför vi har anmälningsplikt vid varje
        körtillfälle.
      </p>
    </div>
  </section>

  <section>
    <div class="wrapper">
      <h2>Styrelse</h2>
      <p>
        Vår styrelse är de som tar viktiga beslut och ser till att föreningen
        hålls vid liv och vidareutvecklas. Det är dock väldigt välkommet med
        hjälp från folk utanför styrelsen också, det behövs.
      </p>

      <table border="1">
        <tr v-for="styrelseMedlem in styrelse" :key="styrelseMedlem.id">
          <td>{{ styrelseMedlem.title }}</td>
          <td>{{ styrelseMedlem.name }}</td>
          <td>{{ styrelseMedlem.number }}</td>
          <td>{{ styrelseMedlem.responsibilities }}</td>
        </tr>
      </table>
    </div>
  </section>

  <!-- <section>
    <h2>Klubbinfo</h2>
    <div class="wrapper">
        <p>Norra Gotlands MHF-Ungdom startades år .... i ....</p>
    </div>
</section> -->
</template>

<script>
import { db } from "../firebase";
import { collection, getDocs, query, orderBy } from "firebase/firestore";

const styrelseCollectionRef = collection(db, "styrelse");
const styrelseCollectionQuery = query(styrelseCollectionRef, orderBy("order"));

export default {
  name: "AboutComp",

  data() {
    return {
      styrelse: [],
    };
  },

  created() {
    document.title = this.$route.meta.title;
  },
  async beforeMount() {
    await this.getStyrelse();
  },

  methods: {
    async getStyrelse() {
      const querySnapshot = await getDocs(styrelseCollectionQuery);

      let _styrelse = [];
      querySnapshot.forEach((doc) => {
        const _styrelseMedlem = {
          id: doc.id,
          title: doc.data().title,
          name: doc.data().name,
          number: doc.data().number,
          responsibilities: doc.data().responsibilities,
          order: doc.data().order,
        };

        _styrelse.push(_styrelseMedlem);
      });

      this.styrelse = _styrelse;
    },
  },
};
</script>

<style scoped>
table {
  border-color: var(--primary-color2);
}
@media (width < 600px) {
  table * {
    font-size: 0.6rem;
  }
}
@media (width < 420px) {
  table * {
    font-size: 0.5rem;
  }
}
</style>
