<template>
  <div id="hero">
    <div class="wrapper">
      <h1>Licenser</h1>
      <p>
        Alla förare från och med 13 år måste enligt Svemos regler inneha en
        godkänd licens.
      </p>
    </div>
  </div>

  <section id="first-section">
    <div class="wrapper">
      <div>
        <p>
          Se
          <a
            href="http://svemo.se"
            target="_blank"
            rel="noopener noreferrer"
            class="link-3"
            >http://svemo.se</a
          >
          för mer information om detta. Undantag görs för körning på däckbanorna
          under de ledarledda aktiviteterna på måndagar, läger mm. Då gäller
          istället ett försäkringsskydd från MHF-Ungdom.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "LicensesComp",
  created() {
    document.title = this.$route.meta.title;
  },
};
</script>
