<template>
    <div id="delete-prompt">
        <h3>OBS!!! Är du säker på att du vill <span class="danger">RADERA</span> denna {{ deleteThing }} <span class="danger">PERMANENT?</span></h3>
        <div class="buttons">
            <button @click="this.$emit('delete');" class="font-size-1 button-1 danger">Radera {{ deleteThing }}</button>
            <button @click="toggleDeletePromt()" class="font-size-1 button-1">Avbryt</button>
        </div>
    </div>
</template>

<script>
export default {
    name: "DeletePromptComp",
    props: {
        deleteThing: String
    },
    methods: {
        toggleDeletePromt() {
            let deletePrompt = document.querySelector("#delete-prompt");
            if (deletePrompt.style.display === "block") {
                deletePrompt.style.display = "none";
            } else {
                deletePrompt.style.display = "block";
            }
        }
    }
}
</script>

<style scoped>
#delete-prompt {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);

    display: none;
    padding: 2rem;
    border-radius: .6rem;

    text-align: center;

    background-color: var(--gray2);
    opacity: 0.9;
}

.buttons {
    display: flex;
    gap: 3rem;
    justify-content: center;
}
</style>