<template>
  <div id="hero">
    <div class="wrapper">
      <h1>Bilder</h1>
      <p>Här hittar du alla våra bildalbum.</p>
    </div>
  </div>

  <section id="first-section">
    <div class="wrapper">
      <ul class="head-categories">
        <li
          v-for="headCategory in images"
          v-bind:key="headCategory.name"
          :class="{ open: headCategory.isOpen }"
          class="head-category"
        >
          <button @click="headCategory.isOpen = !headCategory.isOpen">
            <h2>{{ headCategory.name }}</h2>
          </button>

          <ul class="sub-categories">
            <li
              v-for="subCategory in headCategory.subCategories"
              v-bind:key="subCategory.name"
              class="sub-category"
            >
              <RouterLink
                class="link-4"
                :to="{
                  name: 'ImagesAlbum',
                  params: {
                    headCategory: headCategory.name,
                    subCategory: subCategory.name,
                    images: JSON.stringify(subCategory.images),
                  },
                }"
              >
                {{ subCategory.name }}
              </RouterLink>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  name: "ImagesComp",
  data() {
    return {
      imagesDir: "/bildgalleri/",

      images: [],
    };
  },

  computed: {
    imagesCategoriesAndPaths() {
      // Get array of paths from /public/bildgalleri/bildgalleriMapped.json

      return null;

      /*
      // Gets paths on build
      const imagesPaths = require
        .context(`/public/bildgalleri/`, true, /\.jpg$/)
        .keys();

      let splittedPaths = [];
      imagesPaths.forEach((imagePath) => {
        const imagePathParts = imagePath.substring(2).split("/");
        splittedPaths.push(imagePathParts);
      });

      // Group images in new array based on paths
      const resultArray = [];

      for (let i = 0; i < splittedPaths.length; i++) {
        const headCategoryName = splittedPaths[i][0];
        const subCategoryName = splittedPaths[i][1];
        const image = splittedPaths[i][2];
        const imagePath =
          this.imagesDir +
          headCategoryName +
          "/" +
          subCategoryName +
          "/" +
          image;

        let headCategoryIndex = resultArray
          .map((e) => e.name)
          .indexOf(headCategoryName);
        if (headCategoryIndex == -1) {
          resultArray.push({ name: headCategoryName, subCategories: [] });
          headCategoryIndex = resultArray.length - 1;
        }

        let subCategoryIndex = resultArray[headCategoryIndex].subCategories
          .map((e) => e.name)
          .indexOf(subCategoryName);
        if (subCategoryIndex == -1) {
          resultArray[headCategoryIndex].subCategories.push({
            name: subCategoryName,
            images: [],
          });
          subCategoryIndex =
            resultArray[headCategoryIndex].subCategories.length - 1;
        }

        resultArray[headCategoryIndex].subCategories[
          subCategoryIndex
        ].images.push(imagePath);
      }

      return resultArray;
      */
    },
  },

  created() {
    document.title = this.$route.meta.title;

    this.images = this.imagesCategoriesAndPaths;
  },
};
</script>

<style scoped>
.head-categories {
  display: flex;
  flex-direction: column;
  gap: 5rem;

  list-style: none;
  margin: 0;
  padding: 0;
}

.head-category {
  border-bottom: 1px solid var(--black1);
}

.head-category > button {
  background: none;
  border: none;

  font-size: 1rem;
  text-decoration: underline;
  line-height: 1em;

  position: relative;

  transition: 0.2s all ease-in-out;
}
.head-category > button:hover {
  opacity: 0.7;
}
.head-category > button::before {
  content: url("/src/assets/svgs/down-arrow.svg");

  position: absolute;
  left: 0;
  top: 0;
  transform: translateX(-100%) translateY(10%) rotate(-90deg) scale(0.8);
  transform-origin: center;

  transition: 0.2s all ease-in-out;
}
.head-category.open > button::before {
  transform: translateX(-100%) translateY(10%) rotate(0deg) scale(1);
}
.head-category > button * {
  margin: 0;
}

.sub-categories {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  opacity: 0;
  height: 0;
  transform-origin: top left;
  transform: scale(0);
  transition: 0.2s all ease-in-out;

  overflow-y: hidden;
}

.head-category.open .sub-categories {
  padding: 2rem 2rem;

  opacity: 1;
  height: auto;
  transform: scale(1);

  overflow-y: visible;
}

.sub-category > .link-4 {
  font-size: 1.2rem;
}
</style>
