<template>
  <div id="hero">
    <div class="wrapper">
      <h1>Bra & ha länkar</h1>
    </div>
  </div>

  <section id="first-section">
    <div class="wrapper">
      <ul>
        <li class="link-1">
          <a
            href="http://www.mhf-ungdom.se/"
            target="_blank"
            rel="noopener noreferrer"
            >mhf-ungdom.se MHF-Ungdom</a
          >
        </li>
        <li class="link-1">
          <a
            href="https://www.mhf.se/kontakt/mhf-ungdom/"
            target="_blank"
            rel="noopener noreferrer"
            >mhf.se MHF-Ungdom</a
          >
        </li>
        <li class="link-1">
          <a
            href="https://www.24mx.se/"
            target="_blank"
            rel="noopener noreferrer"
            >24MX</a
          >
        </li>
        <li class="link-1">
          <a
            href="https://www.babbittsonline.com/"
            target="_blank"
            rel="noopener noreferrer"
            >Babbittsonline (sprängskisser till de flesta japanska
            motorcyklarna)</a
          >
        </li>
        <li class="link-1">
          <a
            href="https://www.gec.nu/"
            target="_blank"
            rel="noopener noreferrer"
            >GEC</a
          >
        </li>
        <li class="link-1">
          <a
            href="https://fmck.myclub.se/fmckgotland"
            target="_blank"
            rel="noopener noreferrer"
            >FMCK Gotland</a
          >
        </li>
        <li class="link-1">
          <a
            href="https://idrottonline.se/GotlandsMFsMCK-Motorcykel/"
            target="_blank"
            rel="noopener noreferrer"
            >GMF</a
          >
        </li>

        <li class="link-1">
          <a
            href="https://www.cementa.se/sv/slite"
            target="_blank"
            rel="noopener noreferrer"
            >Cementa Slite</a
          >
        </li>
        <li class="link-1">
          <a
            href="https://www.cykelmchallen.com/"
            target="_blank"
            rel="noopener noreferrer"
            >Cykel & MC-hallen, Visby</a
          >
        </li>
        <li class="link-1">
          <a
            href="http://www.lofqvistcykelmotor.se/"
            target="_blank"
            rel="noopener noreferrer"
            >Löfqvist Cykel & Motor</a
          >
        </li>
        <li class="link-1">
          <a
            href="https://www.gotlandstvatt.se/"
            target="_blank"
            rel="noopener noreferrer"
            >Gotlands Matt- & Handduksuthyrning</a
          >
        </li>
        <li class="link-1">
          <a href="https://jbt.se/" target="_blank" rel="noopener noreferrer"
            >JBT Security</a
          >
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  name: "LinksComp",
  created() {
    document.title = this.$route.meta.title;
  },
};
</script>

<style scoped>
h1 {
  text-align: center;
}

li {
  list-style: none;
  line-height: 200%;
}
li:before {
  content: "> ";
}

a:hover {
  opacity: 0.7;
}
</style>
