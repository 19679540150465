<template>
  <div id="hero">
    <div class="wrapper">
      <h1>Uthyrning av huset</h1>
      <p>
        Norra Gotlands MHF-Ungdom hyr ut huset för att finansiera den årliga
        driften och för att kunna hålla låga kostnader för medlemmar. Hör av er
        för bilder och mer information.
      </p>
    </div>
  </div>

  <section id="first-section">
    <div>
      <h2>Priser 2022</h2>
      <p>Huset med 8 bäddar - 3 500 kr/vecka</p>
    </div>
  </section>
  <section>
    <div class="wrapper">
      <div class="basic-info">
        <h2>Regler för hyresgäster</h2>
        <ul>
          <li>Bytesdagar är lördagar kl. 13.00</li>
          <li>
            Huset lämnas städat. Om det ej är städat kommer kostnad för städning
            att debeteras.
            <a href="#cleaning-rules" class="link-4">Städregler</a>
          </li>
          <li>Cross/Endurobanorna är öppna för körning hela sommaren.</li>
          <li>
            Klubbens medlemmar samt personer som betalat träningsavgift äger
            rätt att använda banorna.
          </li>
          <li>
            Hyresgäst får utnyttja banorna på de ordinarie tiderna.
            <router-link to="/#opening-hours" class="link-4"
              >Läs mer om banornas öppettider</router-link
            >
          </li>
          <li>
            "Lägerverksamhet" är ej tillåten. Om lägerverksamhet skall bedrivas
            gäller andra villkor som beslutas av styrelsen.
          </li>
          <li>SVEMO's miljöpolicy skall följas.</li>
          <li>Öppna spisen får ej användas.</li>
          <li>
            Hyresgäst äger endast tillträde till huset och tvättstugan. Övriga
            lokaler uthyres ej.
          </li>
        </ul>
        <p class="important">
          OBS! Överträdelse medför omedelbar avhysning från gården. Hyran
          återbetalas ej.
        </p>

        <p>
          Ni befinner er vid OTHEM KLINTS 435. Denna adress behöver ni ange om
          ni behöver larma polis, ambulans eller räddningstjänst.
        </p>

        <div class="contact">
          <h4>
            Om något inte fungerar eller ni har andra önskemål eller frågor,
            kontakta:
          </h4>
          <p>Roger Lehrberg, tel. 0739-818 717</p>
          <p>Ronald Jakobsson, tel. 070-558 16 36</p>
          <div class="read-more-link">
            <router-link to="/info" class="link-4">Alla Uppgifter</router-link>
          </div>
        </div>

        <h3 class="classic-font sign">
          Styrelsen för Norra Gotlands MHF-Ungdom
        </h3>
      </div>
      <!-- <div class="track-opening-hours">
            <div>
                <h4>BANORNA ÄR ENDAST ÖPPNA FÖLJANDE TIDER:</h4>
                <p>Måndagar kl. 17.00 - 20.30</p>
                <p>Onsdagar kl. 17.00 - 21.00</p>
                <p>Lördagar kl. 09.00 - 14.00</p>
            </div>
            <div>
                <h4>ALL ÖVRIG TID ÄR KÖRNING FÖRBJUDEN!</h4>
                <p>Cementa spränger nära banan vissa dagar och det är förenat med LIVSFARA att vistas i skogen måndagar
                    till fredagar kl. 07.00 - 16.00.</p>
            </div>
        </div> -->
    </div>
  </section>

  <section id="cleaning-rules">
    <div class="wrapper">
      <h2>REGLER FÖR STÄDNING</h2>
      <div>
        <h4>Lämna stugan i det skick du själv vill finna den, se till att:</h4>
        <ul>
          <li>TV, möbler, bänkytor och hyllor är dammtorkade.</li>
          <li>Skåp och garderober är tömda.</li>
          <li>
            Madrasser, täcken och kuddar är vädrade och ligger snyggt på
            sängarna.
          </li>
          <li>
            Glas, porslin och köksutrustning är diskat och inställt i skåpen.
          </li>
          <li>
            Kyl, frys, spis, ugn, mikrovågsugn och kaffebryggare är rengjorda.
          </li>
          <li>Sopskåpet är tömt, se nedan för sopsortering.</li>
          <li>WC och dusch är rengjorda.</li>
          <li>Speglar är putsade.</li>
          <li>Dammsug och torka alla golv.</li>
          <li>Mattor är vädrade och snyggt tillbakalagda.</li>
          <li>Fönster och dörrar är stängda.</li>
        </ul>
      </div>
      <div>
        <h4>Soporna sorteras enligt följande:</h4>
        <ul>
          <li>Brännbart - svarta kärlet</li>
          <li>Komposterbart - bruna kärlet</li>
        </ul>
      </div>
      <div>
        <h4>
          Sorteringskärl för följande fraktioner finns vid vår miljöstation:
        </h4>
        <ul>
          <li>Pappersförpackningar</li>
          <li>Plastförpackningar</li>
          <li>Metallförpackningar</li>
          <li>Tidningar och Tidskrifter</li>
        </ul>
      </div>
      <div>
        <h4>I butikerna finns "Grön säck" att köpa</h4>
        <ul>
          <li>
            I säcken kan du lämna blandat avfall, dock ej kompost. (max 15 kg).
          </li>
          <li>
            Ställ säcken på fastighetens hämtställe. (Intill övriga kärl).
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "RentHouseComp",
  created() {
    document.title = this.$route.meta.title;
  },
};
</script>

<style>
li {
  margin-bottom: 0.5em;
}

.basic-info {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.contact h4 {
  margin-bottom: 0.3em;
  text-decoration: underline;
}
.contact p {
  margin: 0;
}

.track-opening-hours {
  font-style: italic;
}
.track-opening-hours h4 {
  margin-bottom: 0.3em;

  text-decoration: underline;
}
.track-opening-hours p {
  margin: 0;
}

.sign {
  margin-top: 4rem;
}

.read-more-link {
  margin-top: 0.5em;
}
</style>
