<template>
  <div id="hero">
    <div class="wrapper">
      <h1>Redigera tävlingen: "{{ originalName }}"</h1>
    </div>
  </div>

  <section>
    <div class="form-1">
      <router-link :to="{ name: 'Races' }" class="link-4"
        >Gå tillbaka</router-link
      >

      <div class="input-1">
        <label for="">Namn:</label>
        <input
          type="text"
          v-model="name"
          placeholder="Tävlingens namn..."
          maxlength="50"
          required
        />
      </div>

      <div class="input-1 input-time-1">
        <label for="day">Dag:</label>
        <label class="column-3" for="month">Månad:</label>
        <label class="column-5" for="year">År:</label>

        <input
          class="row-2"
          type="number"
          name="day"
          id="day"
          min="1"
          max="31"
          maxlength="2"
          v-model="raceTime.day"
        />
        <span class="row-2 text-align-center">/</span>
        <input
          class="row-2"
          type="number"
          name="month"
          id="month"
          min="1"
          max="12"
          maxlength="2"
          v-model="raceTime.month"
        />
        <span class="row-2 text-align-center">-</span>
        <input
          class="row-2"
          type="number"
          name="year"
          id="year"
          min="2023"
          max="2100"
          maxlength="4"
          v-model="raceTime.year"
        />
      </div>

      <div class="input-1">
        <label for="">Plats:</label>
        <input
          type="text"
          v-model="place"
          placeholder="Tävlingens plats..."
          maxlength="50"
          required
        />
      </div>

      <div class="input-1">
        <label for="registration-open">Anmälan öppen?</label>
        <div class="display-flex gap03">
          <input
            type="checkbox"
            name="registration-open"
            id="registration-open"
            v-model="registrationOpen"
          />
          <input
            type="url"
            v-model="registrationLink"
            placeholder="Anmälan länk: https://www.svemo.se/..."
            maxlength="200"
            v-if="registrationOpen"
          />
        </div>
      </div>

      <div class="input-1">
        <label for="">Beskrivning:</label>
        <WritingToolsComp
          :content="description"
          @change-content="description = $event"
        />
        <textarea
          v-model="description"
          placeholder="Berätta om tävlingen..."
          class="min-height200"
          id="content"
          maxlength="2000"
          required
        ></textarea>
      </div>

      <div class="edit-race__buttons mt-1">
        <button
          @click="$refs.deletePromptComp.toggleDeletePromt()"
          id="show-delete-prompt"
          class="font-size-1 button-1 danger"
        >
          Radera tävling
        </button>
        <button @click="updateRace()" class="font-size-1 button-1">
          Updatera tävling
        </button>
      </div>
    </div>
    <DeletePromptComp
      ref="deletePromptComp"
      deleteThing="tävling"
      @delete="deleteRace"
    />
  </section>
</template>

<script>
import DeletePromptComp from "@/components/DeletePromptComp.vue";
import WritingToolsComp from "@/components/WritingToolsComp.vue";

import { db } from "../firebase";
import {
  collection,
  getDoc,
  doc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";

const racesCollectionRef = collection(db, "races");

export default {
  name: "RacesEditComp",
  components: {
    DeletePromptComp,
    WritingToolsComp,
  },

  data() {
    return {
      originalName: "",
      id: "",

      name: "",
      raceTime: {
        day: 1,
        month: 1,
        year: 2023,
      },
      place: "",
      registrationLink: null,
      description: "",

      registrationOpen: false,
    };
  },

  created() {
    document.title = this.$route.meta.title;
  },
  mounted() {
    this.loadRaceToEdit();
  },

  computed: {
    isValidUrl() {
      var urlPattern = new RegExp(
        "^(https?:\\/\\/)?" + // validate protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // validate fragment locator

      return !!urlPattern.test(this.registrationLink);
    },
  },
  methods: {
    async loadRaceToEdit() {
      const docRef = doc(racesCollectionRef, this.$route.params.id);
      const _race = await getDoc(docRef);

      if (_race.exists()) {
        this.id = _race.id;
        this.originalName = _race.data().name;

        this.name = _race.data().name;
        this.raceTime = _race.data().raceTime;
        this.place = _race.data().place;
        this.registrationLink = _race.data().registrationLink;
        this.description = _race.data().description;

        if (this.registrationLink) {
          this.registrationOpen = true;
        }
      } else {
        // doc.data() will be undefined in this case
        this.originalName = "Tävlingen finns inte...";
        alert("Denna tävling finns inte!");

        this.$router.push("/races");
      }
    },
    async updateRace() {
      const docRef = doc(racesCollectionRef, this.$route.params.id);

      if (!this.registrationOpen) {
        // If registrationOpen checkbox is turned of, set no registrationlink
        this.registrationLink = "";
      } else {
        // check if registrationLink is valid
        if (!this.isValidUrl) {
          alert(`Anmälningslänken är ej giltig: ${this.registrationLink}`);
          return;
        }
      }

      const date = new Date();

      await updateDoc(docRef, {
        name: this.name.trim(),
        raceTime: this.raceTime,
        place: this.place.trim(),
        registrationLink: this.registrationLink.trim(),
        description: this.description.trim(),
        lastEditDate: `${date.getDate()}/${
          date.getMonth() + 1
        }-${date.getFullYear()}`,
      });

      this.originalName = this.name.trim();
      alert(`Ändringarna på tävlingen "${this.name.trim()}" har nu sparats.`);

      this.$router.push("/races");
    },
    async deleteRace() {
      const docRef = doc(racesCollectionRef, this.$route.params.id);
      await deleteDoc(docRef);

      this.originalName = "BORTTAGEN"; // If doesn't redirect user sees race is deleted in page hero
      alert(`TÄVLINGEN "${this.name}" HAR NU RADERATS!`);

      this.$router.push("/races");
    },
  },
};
</script>

<style scoped>
.edit-race__buttons {
  display: flex;
  gap: 3rem;
  justify-content: center;
}

@media screen and (max-width: 450px) {
  .edit-race__buttons {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
}
</style>
