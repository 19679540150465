<template>
  <div id="hero">
    <div class="wrapper">
      <h1>Närvaroanmälan</h1>
      <p>
        Vi har <strong>absolut anmälningsplikt</strong> för att försäkringen och
        aktivitetsstödet skall gälla. Detta innebär att man måste anmäla sig
        inför varje aktivitetskväll.
      </p>
    </div>
  </div>

  <section id="first-section">
    <div class="wrapper">
      <div>
        <p>Anmälan görs vid inskrivningsdatorn i anslutning till kiosken.</p>
        <p>
          Vid eventuella tekniska bekymmer anmäls närvaro till träningsansvarig.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "NarvaroanmalanComp",
  created() {
    document.title = this.$route.meta.title;
  },
};
</script>
