<template>
  <div id="hero">
    <div class="wrapper">
      <h1>Hyra av fordon</h1>
      <p>
        Då vissa fordon kostar mer i underhåll för föreningen tar vi ut hyra för
        dem. Hyreskostnad för dessa är satt till 100 kr per aktivitetsdag.
      </p>
    </div>
  </div>

  <section id="first-section">
    <div class="wrapper">
      <div>
        <table>
          <h2>Våra fordon:</h2>
          <tr>
            <td>2x Honda CRF150</td>
            <td>- 100kr/aktivitetsdag</td>
          </tr>
          <tr>
            <td>1x Suzuki RM85</td>
            <td>- 100kr/aktivitetsdag</td>
          </tr>
        </table>
      </div>
      <p class="important">
        OBS! Anpassade taxor gäller för läger och liknande.
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: "HireVehiclesComp",
  created() {
    document.title = this.$route.meta.title;
  },
};
</script>
