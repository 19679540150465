<template>
  <div class="writing-tools">
    <button class="button--setting" @click="openAddLinkModal">🔗</button>
    <button
      class="button--setting"
      @click="addWrappingStringsToSelection('<strong>', '</strong>')"
    >
      <strong>B</strong>
    </button>
    <button
      class="button--setting"
      @click="addWrappingStringsToSelection('<i>', '</i>')"
    >
      <i>i</i>
    </button>
  </div>

  <dialog id="add-link-modal" @click="clickedAddLinkModal">
    <form @submit.prevent="addLink()">
      <div class="input-1">
        <label for="new-link">Länk:</label>
        <input
          type="url"
          placeholder="Skriv in länk..."
          v-model="newLink"
          minlength="3"
          maxlength="999"
          required
          id="new-link"
        />
      </div>

      <div class="input-1">
        <label for="new-link-text">Text som visas:</label>
        <input
          type="text"
          placeholder="Skriv in text..."
          v-model="newLinkText"
          minlength="3"
          maxlength="999"
          required
          id="new-link-text"
        />
      </div>

      <div class="link-styles">
        <div>
          <label for="link-2-radio">Link-2:</label>
          <input
            type="radio"
            name="link-2"
            id="link-2-radio"
            value="link-2"
            v-model="newLinkStyle"
          />
        </div>
        <div>
          <label for="link-3-radio">Link-3:</label>
          <input
            type="radio"
            name="link-3"
            id="link-3-radio"
            value="link-3"
            v-model="newLinkStyle"
          />
        </div>
        <div>
          <label for="link-4-radio">Link-4:</label>
          <input
            type="radio"
            name="link-4"
            id="link-4-radio"
            value="link-4"
            v-model="newLinkStyle"
          />
        </div>
      </div>

      <a
        :href="newLink"
        target="_blank"
        rel="noopener noreferrer"
        :class="newLinkStyle"
        >{{ newLinkText }}</a
      >

      <div class="edit-new__buttons mt-1">
        <button
          @click="closeAddLinkModal()"
          class="font-size-1 button-1 danger"
        >
          Avbryt
        </button>
        <input type="submit" value="Lägg till" class="font-size-1 button-1" />
      </div>
    </form>
  </dialog>
</template>

<script>
export default {
  props: {
    content: String,
  },
  emits: ["change-content"],
  data() {
    return {
      newLink: "",
      newLinkText: "",
      newLinkStyle: "link-2",
      addLinkModal: null,
    };
  },

  mounted() {
    this.addLinkModal = document.getElementById("add-link-modal");
  },

  methods: {
    openAddLinkModal() {
      const selection = this.getInputSelection(
        document.getElementById("content")
      );

      this.newLinkText = this.content.slice(0, selection.end).slice(selection.start).trim();

      this.addLinkModal.showModal();
    },
    closeAddLinkModal() {
      this.newLink = "";
      this.newLinkText = "";
      this.addLinkModal.close();
    },
    addLink() {
      const selection = this.getInputSelection(
        document.getElementById("content")
      );

      const newLinkString =
        "<a href='" +
        this.newLink +
        "' target='_blank' rel='noopener noreferrer' class='" +
        this.newLinkStyle +
        "'>" +
        this.newLinkText +
        "</a>";

      let newContent = this.content;
      newContent =
        newContent.slice(0, selection.start) +
        newLinkString +
        newContent.slice(selection.end);

      this.changeContent(newContent);

      this.newLink = "";
      this.newLinkText = "";
      this.addLinkModal.close();
    },
    clickedAddLinkModal(e) {
      const rect = this.addLinkModal.getBoundingClientRect();

      const clickedInDialog =
        rect.top <= e.clientY &&
        e.clientY <= rect.top + rect.height &&
        rect.left <= e.clientX &&
        e.clientX <= rect.left + rect.width;

      if (!clickedInDialog) {
        this.addLinkModal.close();
      }
    },

    addWrappingStringsToSelection(start, end) {
      const selection = this.getInputSelection(
        document.getElementById("content")
      );

      let newContent = this.content;
      newContent =
        newContent.slice(0, selection.start) +
        start +
        newContent.slice(selection.start);
      newContent =
        newContent.slice(0, selection.end + start.length) +
        end +
        newContent.slice(selection.end + start.length);

      this.changeContent(newContent);
    },

    changeContent(newContent) {
      this.$emit("change-content", newContent);
    },

    getInputSelection(el) {
      let start = 0,
        end = 0,
        normalizedValue,
        range,
        textInputRange,
        len,
        endRange;

      if (
        typeof el.selectionStart == "number" &&
        typeof el.selectionEnd == "number"
      ) {
        start = el.selectionStart;
        end = el.selectionEnd;
      } else {
        range = document.selection.createRange();

        if (range && range.parentElement() == el) {
          len = el.value.length;
          normalizedValue = el.value.replace(/\r\n/g, "\n");

          // Create a working TextRange that lives only in the input
          textInputRange = el.createTextRange();
          textInputRange.moveToBookmark(range.getBookmark());

          // Check if the start and end of the selection are at the very end
          // of the input, since moveStart/moveEnd doesn't return what we want
          // in those cases
          endRange = el.createTextRange();
          endRange.collapse(false);

          if (textInputRange.compareEndPoints("StartToEnd", endRange) > -1) {
            start = end = len;
          } else {
            start = -textInputRange.moveStart("character", -len);
            start += normalizedValue.slice(0, start).split("\n").length - 1;

            if (textInputRange.compareEndPoints("EndToEnd", endRange) > -1) {
              end = len;
            } else {
              end = -textInputRange.moveEnd("character", -len);
              end += normalizedValue.slice(0, end).split("\n").length - 1;
            }
          }
        }
      }

      return {
        start: start,
        end: end,
      };
    },
  },
};
</script>

<style scoped>
#add-link-modal:modal {
  width: 80%;
  max-width: 1000px;

  border-radius: 8px;
}
#add-link-modal:modal > form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
#add-link-modal a {
  overflow-wrap: break-word;
  text-align: center;
}

.link-styles {
  display: flex;
  gap: 1.2rem;
}

.writing-tools {
  display: flex;
  align-items: end;
  gap: 0.2rem;
}
</style>
