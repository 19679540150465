<template>
    <header>
        <nav class="nav-main">
            <router-link to="/" id="Home" class="home-nav-logo nav-main"><img
                    src="../assets/images/MHF-Ungdom-logo-transparent.png" alt="MHF-Ungdom logotyp"></router-link>
            <router-link to="/about" id="About" class="nav-link" :class="{ 'current-site': this.currentRouteName == 'About'}">Om oss</router-link>
            <router-link to="/our-facility" id="OurFacility" class="nav-link" :class="{ 'current-site': this.currentRouteName == 'OurFacility'}">Vår anläggning</router-link>
            <router-link to="/member" id="Member" class="nav-link" :class="{ 'current-site': this.currentRouteName == 'Member'}">Bli medlem</router-link>
            <router-link to="/info" id="Info" class="nav-link" :class="{ 'current-site': this.currentRouteName == 'Info'}">Uppgifter</router-link>
            <router-link to="/calendar" id="Calendar" class="nav-link" :class="{ 'current-site': this.currentRouteName == 'Calendar'}">Kalender</router-link>
            <router-link to="/images" id="Images" class="nav-link" :class="{ 'current-site': this.currentRouteName == 'Images'}">Bilder</router-link>
        </nav>

        <nav class="nav-main-mobile">
            <button class="navbar-toggler navbar-toggler__open" @click="toggleCollapseMenu"><img
                    src="../assets/svgs/OpenNavBtn.svg"></button>
            <button class="navbar-toggler navbar-toggler__close hidden" @click="toggleCollapseMenu"><img
                    src="../assets/svgs/CloseBtn.svg"></button>
            <div class="navbar-collapse hidden">
                <router-link to="/" id="Home-mobile" class="nav-link" :class="{ 'current-site': this.currentRouteName == 'Home'}">Hem</router-link>
                <router-link to="/about" id="About-mobile" class="nav-link" :class="{ 'current-site': this.currentRouteName == 'About'}">Om oss</router-link>
                <router-link to="/our-facility" id="OurFacility-mobile" class="nav-link" :class="{ 'current-site': this.currentRouteName == 'OurFacility'}">Vår anläggning</router-link>
                <router-link to="/member" id="Member-mobile" class="nav-link" :class="{ 'current-site': this.currentRouteName == 'Member'}">Bli medlem</router-link>
                <router-link to="/info" id="Info-mobile" class="nav-link" :class="{ 'current-site': this.currentRouteName == 'Info'}">Uppgifter</router-link>
                <router-link to="/calendar" id="Calendar-mobile" class="nav-link" :class="{ 'current-site': this.currentRouteName == 'Calendar'}">Kalender</router-link>
                <router-link to="/images" id="Images-mobile" class="nav-link" :class="{ 'current-site': this.currentRouteName == 'Images'}">Bilder</router-link>

                <router-link to="/" id="Home-mobile" class="navbar-side-brand"><img
                        src="../assets/images/MHF-Ungdom-logo-transparent.png" alt="MHF-Ungdom logotyp"></router-link>
            </div>
        </nav>
    </header>
</template>

<script>
let navbarCollapse = document.querySelector(".navbar-collapse");
let navbarTogglerOpen = document.querySelector(".navbar-toggler__open");
let navbarTogglerClose = document.querySelector(".navbar-toggler__close");

export default {
    name: "HeaderComp",
    data() {
        return {
            currentRouteName: ''
        }
    },
    watch: {
        '$route'() {
            // Set current route
            let _routeArray = this.$route.name.split('/');
            let _routeCurrent = _routeArray[_routeArray.length - 1];

            this.currentRouteName = _routeCurrent;

            // On route change menu should be toggled down
            this.navbarCollapse.classList.add("hidden");
            this.navbarTogglerOpen.classList.remove("hidden");
            this.navbarTogglerClose.classList.add("hidden");
        }
    },
    mounted() {
        this.navbarCollapse = document.querySelector(".navbar-collapse");
        this.navbarTogglerOpen = document.querySelector(".navbar-toggler__open");
        this.navbarTogglerClose = document.querySelector(".navbar-toggler__close");
    },
    methods: {
        toggleCollapseMenu() {
            if (this.navbarCollapse.classList.contains("hidden")) {
                this.navbarCollapse.classList.remove("hidden");
                this.navbarTogglerClose.classList.remove("hidden");
                this.navbarTogglerOpen.classList.add("hidden");
            } else {
                this.navbarCollapse.classList.add("hidden");
                this.navbarTogglerOpen.classList.remove("hidden");
                this.navbarTogglerClose.classList.add("hidden");
            }
        }
    }
}
</script>

<style>
header {
    position: absolute;

    width: 100%;

    font-weight: bold;
    color: var(--black1);
    background-color: var(--primary-color);
}

header img {
    width: 4vw;
}

header a:hover {
    opacity: 0.7;
}

header .current-site {
    text-decoration: underline;
    text-decoration-thickness: 0.2em;
    text-underline-offset: 0.5em;
}



/* Desktop menu */
.nav-main {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2vw;

    padding: .5rem 0;

    letter-spacing: .03em;
}

.nav-main .home-nav-logo {
    margin-right: 2.3rem;
}



/* Mobile menu css */
.nav-main-mobile {
    display: none;
}

.navbar-collapse {
    position: fixed;
    top: 0;
    right: 0;

    height: 100vh;
    min-width: 40vw;

    padding: 0 4rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;

    background-color: var(--gray);

    z-index: 1;
}
.navbar-collapse>a {
    width: 100%;

    font-weight: 500;
    color: white;
}

.navbar-side-brand {
    text-align: center;
    margin-top: 3rem;
}

.navbar-side-brand>img {
    width: 60%;
}

.navbar-toggler {
    position: fixed;
    top: 1.5rem;
    right: 2rem;
    
    background: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    
    z-index: 2;
}
.navbar-toggler>img {
    width: 100%;
}


.admin-logged-in .navbar-toggler,
.admin-logged-in #Home {
    top: 5rem;
}


.hidden {
    display: none;
}



/* On screens that are 600px or less */
@media screen and (max-width: 1000px) {
    .nav-main {
        display: none;
    }
    .nav-main-mobile {
        display: block;
    }
}

@media screen and (max-width: 400px) {
    .admin-logged-in #Home,
    .admin-logged-in .navbar-toggler {
        top: 8rem;
    }
}

@media screen and (max-width: 264px) {

    .admin-logged-in #Home,
    .admin-logged-in .navbar-toggler {
        top: 9rem;
    }
}
</style>
