<template>
  <div id="hero">
    <div class="wrapper">
      <h1>Uppgifter</h1>
      <div class="margin-top-bottom-02">
        <p>Här finns klubbens uppgifter.</p>
        <p>
          För mer information om föreningen och vår styrelse:
          <router-link to="/about" class="link-4">Om oss</router-link>
        </p>
      </div>
    </div>
  </div>

  <section class="section-table" id="first-section">
    <div class="wrapper">
      <table border="1">
        <tr>
          <td>Telefonnummer:</td>
          <td>0738980937 (Mikael Bertilsson)</td>
        </tr>
        <tr>
          <td>E-post:</td>
          <td>
            <a href="mailto:norra.gotland@mhf-ungdom.se"
              >norra.gotland@mhf-ungdom.se</a
            >
          </td>
        </tr>

        <tr>
          <td>Postadress:</td>
          <td>
            <p>Norra Gotlands MHF-Ungdom - Motorcykel och Snöskoter</p>
            <p>Roger Lehrberg, Solåkersvägen 19</p>
            <p>62448 Slite</p>
          </td>
        </tr>

        <tr>
          <td>Besöksadress:</td>
          <td>
            <p>EJ POSTADRESS, Othem Klints 435</p>
            <p>62446 Slite</p>
          </td>
        </tr>

        <tr>
          <td>Föreningsnummer:</td>
          <td>32664-27</td>
        </tr>
        <tr>
          <td>Organisationsnummer:</td>
          <td>834001-7279</td>
        </tr>

        <tr>
          <td>Bankgiro:</td>
          <td>383-3886</td>
        </tr>
        <tr>
          <td>Swish:</td>
          <td>1235113048</td>
        </tr>
      </table>
    </div>
  </section>
  <section>
    <div class="wrapper">
      <h2>Swish</h2>
      <p>Du kan betala med SWISH hos oss. Telnr: 1235113048</p>
      <p>
        Skriv meddelande så att det går att spåra betalningen, t ex K för kiosk
        och tvätt, H för hyra, T för träningsavgift eller M och personnummer för
        medlemsskap.
      </p>
      <p>
        Vi har satt ett minibelopp på 20 kr p g a bankavgifterna per
        transaktion.
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: "InfoComp",
  created() {
    document.title = this.$route.meta.title;
  },
};
</script>

<style>
table {
  border-collapse: collapse;
  border-color: var(--gray2);
}

td {
  padding: 1em;
}

tr td:first-child {
  font-weight: bold;
}
</style>
