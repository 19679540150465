import { createStore } from "vuex";
import router from "@/router/router";
import { auth } from "../firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";

import {
  setCookie,
  deleteCookie,
  getCookie,
  listenCookieChange,
} from "../cookies";

export default createStore({
  state: {
    user: null,
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user;
    },
    CLEAR_USER(state) {
      state.user = null;
    },
  },
  actions: {
    async login({ commit }, details) {
      const { email, password } = details;

      try {
        await signInWithEmailAndPassword(auth, email, password);
      } catch (error) {
        switch (error.code) {
          case "auth/user-not-found":
            alert("Användaren finns inte");
            break;
          case "auth/wrong-password":
            alert("Fel e-post eller lösenord");
            break;
          case "auth/internal-error":
            alert("Skriv lösenord");
            break;
          case "auth/missing-email":
            alert("Skriv e-post");
            break;
          case "auth/invalid-email":
            alert("Felaktig e-post");
            break;
          default: //  + error.code
            alert("Något gick fel");
            break;
        }

        return;
      }

      commit("SET_USER", auth.currentUser);

      // Logged in, set cookie to auto loggin for 15 minutes
      setCookie("isLoggedIn", true, 15);

      router.push("/");
    },
    async logout({ commit }) {
      await signOut(auth);

      commit("CLEAR_USER");

      deleteCookie("isLoggedIn");

      router.go(); // reload page on logout
    },
    fetchUser({ commit, dispatch }) {
      auth.onAuthStateChanged(async (user) => {
        if (user === null) {
          commit("CLEAR_USER");
        } else {
          commit("SET_USER", user);

          if (router.currentRoute.value.path !== "/login") {
            // On page load, loggin or logout - if not login page
            dispatch("checkLoginCookie");
          }
          // Keep Listen for cookie changes every sec
          listenCookieChange(() => {
            // on cookie changed
            dispatch("checkLoginCookie");
          }, 1000);

          if (router.isReady() && router.currentRoute.value.path === "/login") {
            router.push("/");
          }
        }
      });
    },
    checkLoginCookie({ dispatch }) {
      // Check if logged in cookie is still active
      if (!getCookie("isLoggedIn")) {
        if (!auth.currentUser) {
          // If already logged out, no need give confirm to user, just logout fully
          dispatch("logout");
          return;
        }

        // logged in cookie is gone, confirm user to logout
        let _stayLoggedIn = confirm(
          "Vill du fortsätta vara inloggad?\nOK/JA eller AVBRYT/NEJ."
        );
        if (_stayLoggedIn) {
          // set logged in cookie again
          // Logged in, set cookie to auto loggin for 15 minutes
          setCookie("isLoggedIn", true, 15);
        } else {
          // user dosn't want to stay logged in = logout
          dispatch("logout");
        }
      }
    },

    async register({ commit }, details) {
      if (!auth.currentUser) {
        // if not logged in
        console.warn(
          "User not logged in. Can only register new admin-accounts when logged in as admin."
        );
        router.push("/");
      }

      const { email, password } = details;

      if (
        !confirm(`Är du SÄKER på att du vill ge ${email} ADMIN-BEHÖRIGHETER?`)
      ) {
        // Cancel registration
        alert("Du avbröt registreringen.");
        router.push("/");
        return;
      }

      try {
        await createUserWithEmailAndPassword(auth, email, password);
      } catch (error) {
        switch (error.code) {
          case "auth/email-already-in-use":
            alert("E-posten används redan");
            break;
          case "auth/weak-password":
            alert("Svagt lösenord");
            break;
          case "auth/internal-error":
            alert("Skriv lösenord");
            break;
          case "auth/missing-email":
            alert("Skriv e-post");
            break;
          case "auth/invalid-email":
            alert("Felaktig e-post");
            break;
          default: //  + error.code
            alert("Något gick fel");
            break;
        }

        return;
      }

      alert(`Skapade admin-konto med e-post: ${email}.`);

      router.push("/");
    },
  },
});
