<template>
  <div id="hero">
    <div class="wrapper">
      <h1>
        Bilder:
        <RouterLink to="/images" class="link-3"
          ><i>{{ this.$route.params.headCategory }}</i></RouterLink
        >
        / <i>{{ this.$route.params.subCategory }}</i>
      </h1>
    </div>
  </div>

  <section id="first-section">
    <div class="wrapper">
      <ul class="images">
        <li v-for="image in images" :key="image" @click="maximizeImage(image)">
          <img :src="image" alt="Föreningsbild." />
        </li>
      </ul>

      <div
        class="maximized-img"
        :class="{ active: currentImageMaximized != null }"
        @click="maximizeImage(currentImageMaximized)"
      >
        <img :src="currentImageMaximized" alt="Förstorad bild på föreningen." />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ImagesAlbumComp",
  data() {
    return {
      images: [],

      currentImageMaximized: null,
    };
  },

  computed: {},

  created() {
    document.title = this.$route.meta.title;

    this.images = JSON.parse(this.$route.params.images);
  },

  methods: {
    maximizeImage(imagePath) {
      if (this.currentImageMaximized != null) {
        this.currentImageMaximized = null;
        return;
      }

      this.currentImageMaximized = imagePath;
    },
  },
};
</script>

<style scoped>
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.images {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-gap: 0.5rem;
  -moz-column-gap: 0.5rem;
  column-gap: 0.5rem;

  overflow: hidden;
}

.images li {
  display: inline-block;
  width: 100%;

  cursor: pointer;

  transform: scale(1);
  transform-origin: center;
  transition: 0.2s all ease-in-out;

  overflow: hidden;
}
.images li:hover {
  opacity: 0.95;
  transform: scale(1.01);
  border-radius: 4px;
}

.images img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: block;
}

/* ___ MAXIMIZED IMG ___ */
.maximized-img {
  position: fixed;
  top: 0;
  left: 0;

  box-sizing: border-box;
  padding: 10%;

  z-index: 5;

  opacity: 0;
  visibility: hidden;
  height: 0;
  width: 0;
}

.maximized-img.active {
  width: 100%;
  height: 100vh;
  opacity: 1;
  visibility: visible;

  background-color: rgba(10, 10, 10, 0.7);
}
.maximized-img::after {
  content: "Klicka för att förminska";
  color: white;
  text-shadow: 2px 2px 1px black;
  font-size: 1.5rem;
  font-weight: bold;
  text-decoration: underline;
  text-align: center;

  cursor: pointer;

  width: 100vw;
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
}

.maximized-img img {
  max-width: 100%;
  height: 100%;
  object-fit: contain;

  display: block;
  margin: 0 auto;

  position: relative;

  cursor: pointer;

  transform: scale(0);
  transform-origin: center;
  transition: 0.2s all ease-in-out;
}
.maximized-img.active img {
  transform: scale(1);
}
.maximized-img.active img:hover {
  opacity: 0.9;
  transform: scale(0.99);
  border-radius: 4px;
}

/* --- MEDIA QUERIES --- */
@media only screen and (max-width: 800px) {
  .images {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
}
</style>
