<template>
  <footer>
    <div class="container-contact">
      <h5>Kontakt</h5>
      <div class="column">
        <div class="row">
          <p>MHF, Othem Klints 435</p>
          <p>62448 Slite</p>
        </div>
        <div class="row">
          <a class="link-1" href="tel:+0738980937">0738980937</a>
        </div>
        <div class="row">
          <a class="link-1" href="mailto:norra.gotland@mhf-ungdom.se"
            >norra.gotland@mhf-ungdom.se</a
          >
        </div>
        <div class="row">
          <router-link to="/info" class="link-4">Uppgifter</router-link>
        </div>
      </div>
    </div>
    <div class="container container-facebook">
      <a
        class="facebook-link"
        href="https://sv-se.facebook.com/groups/165001056851855/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src="../assets/svgs/facebook-white-icon.svg" />
        <span class="link-4">Norra Gotlands MHF-Ungdom</span></a
      >
    </div>
    <div class="container made-by">
      <p class="copyright-text">
        Copyright © {{ new Date().getFullYear() }}, Norra Gotlands MHF-Ungdom
      </p>
      <p class="made-by-text">Hemsidan gjord av Anton Lehrberg</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterComp",
};
</script>

<style scoped>
footer {
  background-color: var(--secondary-color);
  color: white;

  padding: 8vh 12vw;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
}

footer .link-4 {
  color: var(--primary-color);
}

.container-contact h5 {
  font-size: 1.5rem;
  margin: 0.9em 0;
}

.container-contact .column {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.container-contact .row p {
  margin: 0;
}

.container-facebook {
  display: flex;
  justify-content: center;
}
.facebook-link {
  display: flex;
  align-items: center;
  /* gap: 0.8rem; */
}
.facebook-link img {
  width: 1.6em;
  margin-right: 0.8rem;
}
.facebook-link:hover img {
  opacity: 0.8;
}

.made-by {
  text-align: right;
}
.made-by .copyright-text {
  font-weight: 500;
}
.made-by .made-by-text {
  font-weight: 100;
  color: rgba(255, 255, 255, 0.707);

  margin-top: 1.3em;
}

/* On screens that are 600px or less */
@media screen and (max-width: 1500px) {
  footer {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }

  .container-contact {
    grid-column: 1;
    grid-row: 1 / -1;
  }
  .container-contact > h5 {
    margin-top: 0;
  }
  .container {
    grid-column: 2;
    align-self: end;
  }
  .container-facebook {
    justify-content: end;
    align-self: start;
  }

  .made-by-text {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 690px) {
  footer {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, auto);

    gap: 3rem;
    justify-items: center;
  }

  .container-contact {
    grid-row: 1;
    text-align: center;
  }
  .container {
    grid-column: 1;
    grid-row: 3;
    align-self: center;
  }
  .container-facebook {
    grid-row: 2;
  }

  .copyright-text,
  .made-by-text {
    text-align: center;
  }
}
</style>
