<template>
  <div id="hero">
    <div class="wrapper">
      <h1>Resultat tävlingar</h1>
      <p>Se hur du och dina vänner presterade.</p>
    </div>
  </div>

  <section>
    <div class="wrapper">
      <h3>Här kan du se kommande resultat från tävlingar.</h3>
    </div>
  </section>
</template>

<script>
export default {
  name: "ResultsComp",
  created() {
    document.title = this.$route.meta.title;
  },
};
</script>
