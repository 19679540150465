<template>
  <div id="hero">
    <div class="wrapper">
      <h1>Lägg till en kommande tävling</h1>
    </div>
  </div>

  <section>
    <div class="form-1">
      <router-link to="races" class="link-4">Gå tillbaka</router-link>

      <div class="input-1">
        <label for="">Namn:</label>
        <input
          type="text"
          v-model="name"
          placeholder="Tävlingens namn..."
          maxlength="50"
          required
        />
      </div>

      <div class="input-1 input-time-1">
        <label for="day">Dag:</label>
        <label class="column-3" for="month">Månad:</label>
        <label class="column-5" for="year">År:</label>

        <input
          class="row-2"
          type="number"
          name="day"
          id="day"
          min="1"
          max="31"
          maxlength="2"
          v-model="raceTime.day"
        />
        <span class="row-2 text-align-center">/</span>
        <input
          class="row-2"
          type="number"
          name="month"
          id="month"
          min="1"
          max="12"
          maxlength="2"
          v-model="raceTime.month"
        />
        <span class="row-2 text-align-center">-</span>
        <input
          class="row-2"
          type="number"
          name="year"
          id="year"
          min="2023"
          max="2100"
          maxlength="4"
          v-model="raceTime.year"
        />
      </div>

      <div class="input-1">
        <label for="">Plats:</label>
        <input
          type="text"
          v-model="place"
          placeholder="Tävlingens plats..."
          maxlength="50"
          required
        />
      </div>

      <div class="input-1">
        <label for="registration-open">Anmälan öppen?</label>
        <div class="display-flex gap03">
          <input
            type="checkbox"
            name="registration-open"
            id="registration-open"
            v-model="registrationOpen"
          />
          <input
            type="url"
            v-model="registrationLink"
            placeholder="Anmälan länk: https://www.svemo.se/..."
            maxlength="200"
            v-if="registrationOpen"
          />
        </div>
      </div>

      <div class="input-1">
        <label for="">Beskrivning:</label>
        <WritingToolsComp
          :content="description"
          @change-content="description = $event"
        />
        <textarea
          v-model="description"
          placeholder="Berätta om tävlingen..."
          class="min-height200"
          id="content"
          maxlength="2000"
          required
        ></textarea>
      </div>
      <button class="font-size-1 button-1 mt-1" @click="addRace()">
        Lägg till
      </button>
    </div>
  </section>
</template>

<script>
import { auth, db } from "../firebase";
import { collection, addDoc } from "firebase/firestore";

import WritingToolsComp from "@/components/WritingToolsComp.vue";

const racesCollectionRef = collection(db, "races");

export default {
  name: "RacesAddComp",
  components: {
    WritingToolsComp,
  },

  data() {
    return {
      name: "",
      raceTime: {
        day: 1,
        month: 1,
        year: 2023,
      },
      place: "",
      registrationLink: null,
      description: "",

      registrationOpen: false,
    };
  },

  created() {
    document.title = this.$route.meta.title;
  },

  computed: {
    isValidUrl() {
      var urlPattern = new RegExp(
        "^(https?:\\/\\/)?" + // validate protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // validate fragment locator

      return !!urlPattern.test(this.registrationLink);
    },
  },
  methods: {
    async addRace() {
      if (!auth.currentUser) {
        alert("Krävs admin-inloggning för att göra denna åtgärden!");
        return;
      }

      if (!this.registrationOpen) {
        // If registrationOpen checkbox is turned of, set no registrationlink
        this.registrationLink = "";
      } else {
        // check if registrationLink is valid
        if (!this.isValidUrl) {
          alert(`Anmälningslänken är ej giltig: ${this.registrationLink}`);
          return;
        }
      }

      const date = new Date();

      // Add a new document with a generated id.
      await addDoc(racesCollectionRef, {
        // await to make sure script waits for adding doc before run "getRaces()"
        name: this.name.trim(),
        raceTime: this.raceTime,
        place: this.place.trim(),
        registrationLink: this.registrationLink.trim(),
        description: this.description.trim(),
        lastEditDate: `${date.getDate()}/${
          date.getMonth() + 1
        }-${date.getFullYear()}`,
      });

      alert(`Tävlingen "${this.name.trim()}" har nu lagts till.`);

      this.$router.push("/races");
    },
  },
};
</script>
