<template>
  <div id="hero">
    <div class="wrapper">
      <h1>404 - Sidan kunde inte hittas</h1>
    </div>
  </div>

  <section>
    <div class="wrapper">
      <p>
        Nu blev något fel... Sidan du vill besöka går inte att nå. Det kan vara
        en gammal eller trasig länk, eller så är felet tillfälligt. Pröva gärna
        att navigera via menyn ovan ↑ eller klicka på länken nedan ↓
      </p>
      <router-link to="/" class="link-4"
        >Klicka här för att komma till hem</router-link
      >
    </div>
  </section>
</template>

<script>
export default {
  name: "PageNotFoundComp",
  created() {
    document.title = this.$route.meta.title;
  },
};
</script>
