<template>
  <div id="hero">
    <div class="wrapper">
      <h1>Sponsorer</h1>
      <p>Våra sponsorer är högst nödvändiga för vår överlevnad!</p>
      <p>Klicka på företagsbilderna för att komma till företagens hemsidor.</p>
    </div>
  </div>

  <section id="first-section">
    <div class="wrapper">
      <ul>
        <li>
          <a
            href="https://www.cementa.se/sv/slite"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="../assets/svgs/sponsors/cementa.svg" alt="Cementa bild"
          /></a>
        </li>
        <li>
          <a
            href="https://www.cykelmchallen.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="../assets/svgs/sponsors/cykel-mchallen.svg"
              alt="Cykel & MC-hallen bild"
          /></a>
        </li>
        <li>
          <a
            href="http://www.lofqvistcykelmotor.se/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="../assets/svgs/sponsors/löfqvist.svg"
              alt="Löfqvist Cykel & Motor bild"
          /></a>
        </li>
        <li>
          <a
            href="https://www.gotlandstvatt.se/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="../assets/svgs/sponsors/gotlands-mattohandduksuthyrning.svg"
              alt="Gotlands Matt- & Handduksuthyrning bild"
          /></a>
        </li>
        <li>
          <a href="https://jbt.se/" target="_blank" rel="noopener noreferrer">
            <img
              src="../assets/svgs/sponsors/jbt-security.svg"
              alt="JBT Security bild"
          /></a>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  name: "SponsorsComp",
  created() {
    document.title = this.$route.meta.title;
  },
};
</script>

<style scoped>
ul {
  width: 100%;
  list-style: none;
  padding: 0;

  display: grid;
  justify-content: center;
  gap: 2rem;
}

a {
  display: block;
  height: 7rem;
  width: fit-content;
  margin: 0 auto;

  border: 1px solid black;
  border-radius: 0.2rem;

  overflow: hidden;

  transition: 0.2s opacity ease-in-out, 0.2s box-shadow ease-in-out;
}
a:hover {
  box-shadow: 0px 0px 10px 0 black;
}
a img {
  height: 100%;
  max-width: 100%;
  object-fit: contain;

  display: block;
}
</style>
