<template>
  <div id="hero">
    <div class="wrapper">
      <h1>Logga in</h1>
    </div>
  </div>

  <section class="forms">
    <div class="wrapper">
      <form class="login" @submit.prevent="login">
        <input
          type="email"
          placeholder="E-postadress"
          maxlength="320"
          autocomplete="email"
          autofocus
          v-model="login_form.email"
        />
        <input
          type="password"
          placeholder="Lösenord"
          maxlength="30"
          autocomplete="current-password"
          v-model="login_form.password"
        />
        <input type="submit" value="Logga in" class="button-1 mt-1" />
      </form>
    </div>
  </section>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";

export default {
  name: "LoginComp",
  created() {
    document.title = this.$route.meta.title;
  },
  setup() {
    const login_form = ref({});
    const store = useStore();

    const login = () => {
      store.dispatch("login", login_form.value);
    };

    return {
      login_form,
      login,
    };
  },
};
</script>

<style scoped>
.login {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  align-items: center;
}

.login input:not([type="submit"]) {
  font-size: 1.5rem;

  box-sizing: border-box;
  padding: 0.2em 0.4em;
  max-width: 30em;

  background: none;
  border: none;
  border-bottom: 0.1em solid var(--black1);
}
</style>
