<template>
  <div id="hero">
    <div class="wrapper">
      <h1>Kommande tävlingar</h1>
      <p>
        Luciaracet är en klassiker som vi försöker genomföra varje år. Med
        tillräckligt intresse och engagemang kan även fler tävlingar anordnas.
      </p>
    </div>
  </div>

  <section
    v-if="$store.state.user"
    style="background-color: var(--black1); color: var(--white)"
  >
    <h2 class="">Adminverktyg</h2>
    <router-link to="/races-add" class="link-4"
      >Lägg till kommande tävling</router-link
    >
  </section>

  <section v-for="_race in racesList" :key="_race.id" v-bind:id="_race.id">
    <div class="wrapper race">
      <h3>{{ _race.name }}</h3>
      <div class="part gap05">
        <div class="part-icon-left">
          <img src="../assets/svgs/DateCalendar.svg" alt="Kalender ikon." />
          <p class="cursive">
            {{ _race.raceTime.day }}/{{ _race.raceTime.month }} -
            {{ _race.raceTime.year }}
          </p>
        </div>
        <div class="part-icon-left">
          <img src="../assets/svgs/PlacePoint.svg" alt="Plats ikon." />
          <p class="cursive">{{ _race.place }}</p>
        </div>
        <div class="part-icon-left">
          <img src="../assets/svgs/LinkIcon.svg" alt="Länk ikon." />
          <p class="" v-if="!_race.registrationLink">Anmälan ej öppen</p>
          <a
            :href="_race.registrationLink"
            target="_blank"
            class="link-3"
            v-if="_race.registrationLink"
            >Anmäl här</a
          >
        </div>
      </div>
      <div class="part">
        <p
          v-for="paragraph in _race.description.split('\n')"
          :key="paragraph"
          v-html="paragraph"
        ></p>
      </div>
      <p class="cursive">Senast redigerad: {{ _race.lastEditDate }}</p>

      <div class="new_buttons" v-if="$store.state.user">
        <router-link
          :to="{ name: 'RacesEdit', params: { id: _race.id } }"
          class="link-1 danger"
          >✎ Redigera</router-link
        >
      </div>
    </div>
  </section>

  <section v-if="racesList.length <= 0">
    <div class="wrapper race">
      <h3>Ingen kommande tävling planerad :(</h3>
    </div>
  </section>
</template>

<script>
import { db } from "../firebase";
import { collection, getDocs, query, orderBy } from "firebase/firestore";

export default {
  name: "RacesComp",
  data() {
    return {
      racesList: [],
    };
  },
  created() {
    document.title = this.$route.meta.title;
  },
  async beforeMount() {
    await this.getRaces();
  },
  methods: {
    async getRaces() {
      const q = query(
        collection(db, "races"),
        orderBy("raceTime.year"),
        orderBy("raceTime.month"),
        orderBy("raceTime.day")
      );
      const querySnapshot = await getDocs(q);
      let _races = [];

      querySnapshot.forEach((doc) => {
        // get all up comming races
        const _race = {
          id: doc.id,
          name: doc.data().name,
          raceTime: doc.data().raceTime,
          place: doc.data().place,
          registrationLink: doc.data().registrationLink,
          description: doc.data().description,
          lastEditDate: doc.data().lastEditDate,
        };

        _races.push(_race);
      });
      this.racesList = _races;
    },
  },
};
</script>

<style scoped>
.race {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.race > * {
  margin: 0;
}

.part {
  display: flex;
  flex-direction: column;
}

.part p {
  margin: 0;
}

.gap05 {
  gap: 0.5rem;
}

.part-icon-left {
  display: flex;
  align-items: center;
}

.part-icon-left img {
  height: 1.5em;
  margin-right: 0.3em;
}
</style>
