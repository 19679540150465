<template>
    <div v-bind:class="{ 'admin-logged-in': $store.state.user }">
        <div class="login-logout-nav login-logout-nav--top" v-if="$store.state.user">
            <p v-if="$store.state.user" class="danger">Du är inloggad som administratör.</p>
            <button class="button-1" @click="$store.dispatch('logout')">Logga ut</button>
        </div>

        <HeaderComp />

        <main>
            <!-- :key="$route.path"  makes sure page will reload on Vue params change -->
            <router-view :key="$route.path"></router-view> 
        </main>

        <FooterComp />

        <div class="login-logout-nav">
            <router-link class="button-1" to="/register" v-if="$store.state.user">Registrera ny</router-link>
            <button class="button-1" @click="$store.dispatch('logout')" v-if="$store.state.user">Logga ut</button>
            <router-link class="button-1" to="/login" v-if="!$store.state.user">Logga in</router-link>
        </div>
    </div>
</template>

<script>
import HeaderComp from './components/HeaderComp.vue';
import FooterComp from './components/FooterComp.vue';

import { onBeforeMount } from 'vue';
import { useStore } from 'vuex';

// document.title = 'MHF Othem'; // standard title

export default {
    name: 'App',
    components: {
        HeaderComp,
        FooterComp
    },
    setup() {
        const store = useStore();

        onBeforeMount(() => {
            store.dispatch('fetchUser')
        })

        // Log out admin when page is closed
        // document.addEventListener('beforeunload', store.dispatch('logout'))
    }
}
</script>

<style>
@import './assets/css/main.css';


.login-logout-nav {
    background-color: var(--secondary-color);
    display: flex;
    justify-content: right;
    gap: 2rem;
    padding: .5rem;
}
.login-logout-nav > .button-1 {
    font-size: .8rem;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: white;
    font-weight: normal;
    
    background-color: var(--secondary-color);
    border-color: white;

    opacity: .6;
}

.login-logout-nav--top {
    background-color: white;
    padding: 0.5rem 1rem;

    position: fixed;
    top: 0;

    opacity: 0.8;

    z-index: 1;

    border-radius: 0 0 .6rem .6rem;
}


@media screen and (max-width: 1600px) {
    .admin-logged-in .login-logout-nav--top {
        width: 100%;
        box-sizing: border-box;
    }

    .admin-logged-in main {
        margin-top: 4.5rem;
    }
}
@media screen and (max-width: 1000px) {
    .admin-logged-in main {
        margin-top: 4.1rem;
    }
}
@media screen and (max-width: 400px) {
    .admin-logged-in .login-logout-nav--top {
        flex-direction: column;
        gap: 0;
    }

    .admin-logged-in main {
        margin-top: 6.8rem;
    }
}
@media screen and (max-width: 264px) {
    .admin-logged-in main {
        margin-top: 8.1rem;
    }
}
</style>
