// Cookie functions
function setCookie(name, value, minutesToLive){
    const date = new Date();
    date.setTime(date.getTime() +  (minutesToLive * 60 * 1000));
    let expires = "expires=" + date.toUTCString();
    document.cookie = `${name}=${value}; ${expires}; path=/`
}
function deleteCookie(name){
    setCookie(name, null, null);
}
function getCookie(name){
    const cDecoded = decodeURIComponent(document.cookie);
    const cArray = cDecoded.split("; ");
    let result = null;
    
    cArray.forEach(element => {
        if(element.indexOf(name) == 0){
            result = element.substring(name.length + 1)
        }
    })
    return result;
}


function listenCookieChange(callback, interval = 1000) {
    let lastCookie = document.cookie;
    setInterval(()=> {
        let cookie = document.cookie;
        if (cookie !== lastCookie) {
            try {
                callback();
            } finally {
                lastCookie = cookie;
            }
        }
    }, interval);
}


export { setCookie, deleteCookie, getCookie, listenCookieChange }