<template>
  <div id="hero">
    <div class="wrapper">
      <h1>Bli medlem</h1>
      <p>
        Att bli medlem hos oss kostar endast 400 kr per år, och ger många
        fördelar.
      </p>
    </div>
  </div>

  <section id="first-section">
    <div class="wrapper">
      <p>Medlemsavgift för kalenderår 2022: 400kr.</p>
      <p>
        För nytillkomna på höstterminen finns möjlighet till reducerad avgift om
        200 kr för endast hösttermin.
      </p>
      <p>
        Medlemsavgiften betalas in på bankgiro 383-3886. Kom ihåg att fylla i
        namn och personnummer.
      </p>

      <h3>Vad ingår?</h3>
      <ul>
        <li>
          På öppna måndagar/ledarledda aktivitetsdagar ingår i medlemsavgiften
          att barn och ungdomar fritt kan nyttja fordonsparken och våra banor
          (för vissa fordon tas dock en hyra ut).
        </li>
        <li>
          Övriga dagar då banorna är öppna tas en träningsavgift ut på
          endurobanan (inklusive jordbanedelen), men ej för däckbanorna.
        </li>
      </ul>

      <div>
        <p class="important">
          Observera Svemos licensregler för förare från året de fyller 13 år och
          äldre.
        </p>
        <a
          href="https://www.svemo.se/Service/forforare/Licens/FAQ"
          target="_blank"
          rel="noopener noreferrer"
          class="link-4"
        >
          Läs vidare</a
        >
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "MemberComp",
  created() {
    document.title = this.$route.meta.title;
  },
};
</script>
